import { Experience } from '../../models/Experience'

export const ExperienceList: Experience[] = [
  {
    logoPath: 'altogic.png',
    company: 'Altogic',
    location: 'Job Location: Wilmington, Delaware, United States',
    startDate: 'June 2022',
    endDate: 'March 2023',
    description:
      "I developed applications and wrote articles using Next.js, Vue.js, and React.js at a startup that provides backend-as-a-service products. I also introduced the project to people. In the projects I worked on, I designed all the backend logic myself. The projects I was involved in were Discusy.com and Opinate.io.      ",
    jobTitle: 'Developer Advocate',
    head: 'Technologies used',
    technologies: [
      'MongoDB',
      'Next JS ',
      'Tailwind CSS',
      'Redux.js',
      'React.js',
      'Javascript',
      'Marketing',
      'Article Writing',
      'Search Engine Optimization (SEO)',
    ],
  },
  {
    logoPath: 'mastio.png',
    company: 'Mastio',
    location: 'Istanbul, Turkey',
    startDate: 'May 2020',
    endDate: 'March 2023',
    description:
      "Buildig web3 applications using React.js, Next.js, SASS, Tailwind, Web3.js and Etherium.js",
    jobTitle: 'Full Stack Developer',
    head: 'Technologies used',
    technologies: [
      'MongoDB',
      'Next.js',
      'Tailwind CSS',
      'Redux.js',
      'Redux Saga',
      'React.js',
      'Javascript',
      'Typescript',
      'Etherium.js',
      'SASS',
      'Web3.js',
    ],
  },
  {
    logoPath: 'vodafone.png',
    company: 'Vodafone',
    location: 'Istanbul, Turkey',
    startDate: 'January 2022',
    endDate: 'May 2022',
    description:
      "I was employed by a company that provided support and development for the customer panel of Vodafone's internet service as an outsourced company.",
    jobTitle: 'Full Stack Developer',
    head: 'Technologies used',
    technologies: [
      'MSSQL',
      'Vue js',
      'HTML5',
      'CSS',
      'jQuery',
      'C#',
      '.NET Framework',
      '.NET Core',
      'Model-View-Controller (MVC)',
      'JavaScript',
      'ASP.NET MVC',
    ],
  },
  {
    logoPath: 'labdroids.png',
    company: 'Labdroids Information Technologies',
    location: 'Istanbul, Turkey',
    startDate: 'January 2022',
    endDate: 'May 2022',
    description:
      "I was involved in both backend and frontend development, working on new projects based on customer requirements and updating existing projects to meet current needs. Overall, I played a key role in developing software solutions that met the customers' needs and contributed to the smooth operation of the team.I gained experience in project management, teamwork, and Agile methodology.",
    jobTitle: 'Full Stack Developer',
    head: 'Technologies used',
    technologies: [
      'MSSQL',
      'Vue js',
      'HTML5',
      'CSS',
      'jQuery',
      'C#',
      '.NET Framework',
      '.NET Core',
      'Model-View-Controller (MVC)',
      'JavaScript',
      'ASP.NET MVC',
    ],
  },
  {
    logoPath: 'ustek.png',
    company: 'USTEK RFID SOLUTIONS',
    location: 'Istanbul, Turkey',
    startDate: 'May 2019',
    endDate: 'November 2019',
    description:
      "I developed RFID based mobile applications using Java and REST APIs",
    jobTitle: 'Intern',
    head: 'Technologies used',
    technologies: [
      'Java',
      'REST APIs',
      'JSON Web Token (JWT)',
      'Radio-Frequency Identification (RFID)',
      'Mobile Applications',
    ],
  },
  {
    logoPath: 'pals.png',
    company: 'PALS ELECTRONICS',
    location: 'Istanbul, Turkey',
    startDate: 'November 2019',
    endDate: 'May 2020',
    description:
      "Building RFID based systems using Arduino and C. I also developed a mobile application for the system I developed.",
    jobTitle: 'Intern',
    head: 'Technologies used',
    technologies: [
      'Arduino',
      'C',
      'Java',
      'Radio-Frequency Identification (RFID)',
      'Mobile Applications',

    ],
  },
]
